import {
  AssetCategory,
  AssetDetailsSortBy,
  AssetPageTab,
  SortDirection,
} from '@spectral/types'
import pull from 'lodash/pull'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import uniqBy from 'lodash/uniqBy'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { IssueStatus, IssueKind } from '@spectral/types/issue'
import { castArray } from 'lodash'
import { toElementId } from '../../blocks/asset-page/utils'
import assetPageTracker from '../../common/track/asset-page'
import { scrollToElementWithId } from '../../common/utils'
import useExportToFile from '../../hooks/useExportToFile'
import useIssuesActionIntegrations from '../../hooks/useIssuesActionIntegrations'
import { useIssuesTableActions } from '../../hooks/useIssuesTableActions'
import useListPageManagement from '../../hooks/useListPageManagement'
import useQueryStringState from '../../hooks/useQueryStringState'
import { Dispatch, select } from '../../redux/store'
import { SubPage } from '../../components/tab/sub-page'
import OpenSourceView from '../../blocks/asset-page/open-source/open-source-view/view'

export const openSourceListInitialParams = {
  page: 1,
  pageSize: 10,
  sortBy: AssetDetailsSortBy.COUNT,
  sortDirection: SortDirection.DESC,
  tab: AssetPageTab.OpenSource,
}

const filtersKeys = [
  'tab',
  'path',
  'severity',
  'status',
  'assignees',
  'detectorId',
  'issueId',
  'packagesNames',
  'OpenSourceIssueTypes',
]

const openSourceListInitialFiltersParams = pick(
  openSourceListInitialParams,
  filtersKeys
)

const OpenSourceList = ({ fetchAsset, asset }) => {
  const dispatch: Dispatch = useDispatch()
  const { assetId } = useParams()
  const [
    currentParamsValues,
    saveParamValueInQueryString,
  ] = useQueryStringState(['expandedFile', 'issueId'])
  const { expandedFile } = currentParamsValues

  const { openSourceFiles, pagination, status, filtersData } = useSelector(
    select.AssetPage.openSourceFilesList
  )
  const isDownloadingExport = useSelector(select.AssetPage.isDownloadingExport)
  const {
    status: { isLoadingAssignableMembers, isAssignableMembersLoaded },
    assignableMembers,
  } = useSelector(select.AssetPage.assetPage)
  const { loaded, isRefreshing, isRefreshingOpenSourceFileDetails } = status

  const { integrations } = useIssuesActionIntegrations()
  const [selectedIssues, setSelectedIssues] = useState([])
  const onIssueSelectionChanged = (issuesToChange, isSelected) => {
    const updatedSelectedIssues = isSelected
      ? uniqBy([...selectedIssues, ...issuesToChange], 'pid')
      : pull(selectedIssues, ...issuesToChange)
    setSelectedIssues([...updatedSelectedIssues])
  }
  const resetTableState = () => {
    setSelectedIssues([])
  }
  const [
    currentQueryStringParams,
    setQueryStringParam,
    clearQueryStringParam,
    onPaginationChange,
    trackFilter,
    trackSort,
    fetchData,
  ] = useListPageManagement(
    filtersKeys,
    openSourceListInitialParams,
    dispatch.AssetPage.fetchOpenSourceFiles,
    dispatch.AssetPage.resetOpenSourceFiles,
    assetPageTracker.filter.bind(assetPageTracker),
    assetPageTracker.sort.bind(assetPageTracker),
    assetPageTracker.pagination.bind(assetPageTracker),
    { assetId: decodeURIComponent(assetId) }
  )

  const [isDataFiltered, setIsDataFiltered] = useState(false)
  useEffect(() => {
    const currentParamsValuesPick = pick(currentQueryStringParams, filtersKeys)
    setIsDataFiltered(
      !isEqual(currentParamsValuesPick, openSourceListInitialFiltersParams)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(currentQueryStringParams)])

  const [exportToCsv] = useExportToFile(
    () => assetPageTracker.export.bind(assetPageTracker)('csv', true),
    () =>
      dispatch.AssetPage.exportIssues({
        ...currentQueryStringParams,
        category: AssetCategory.Code,
        issueKinds: castArray(IssueKind.Oss),
        assetId: [assetId],
        output: 'csv',
      }),
    'csv',
    currentQueryStringParams
  )

  const [exportToPdf] = useExportToFile(
    () => assetPageTracker.export.bind(assetPageTracker)('pdf', true),
    () =>
      dispatch.AssetPage.exportIssues({
        ...currentQueryStringParams,
        category: AssetCategory.Code,
        assetId: [assetId],
        issueKinds: castArray(IssueKind.Oss),
        output: 'pdf',
      }),
    'pdf',
    currentQueryStringParams
  )

  const exportActions = useMemo(() => {
    return {
      csv: { active: true, onExport: exportToCsv },
      pdf: { active: true, onExport: exportToPdf },
    }
  }, [exportToCsv, exportToPdf])

  const refetchAllData = useCallback(async () => {
    await fetchAsset()
    fetchData()
    if (expandedFile) {
      await dispatch.AssetPage.fetchOpenSourceFilesDetails({
        ...currentQueryStringParams,
        openSourceFiles: [expandedFile],
        assetId,
      })
    }

    resetTableState()
  }, [
    assetId,
    currentQueryStringParams,
    dispatch.AssetPage,
    expandedFile,
    fetchAsset,
    fetchData,
  ])

  const handlers = useIssuesTableActions('asset_page', {
    ignore: refetchAllData,
    snooze: refetchAllData,
    resolve: refetchAllData,
    unResolve: refetchAllData,
    assigneeChange: refetchAllData,
    assigneeRemove: refetchAllData,
    severityChange: () => {
      fetchData({ updateOnlyFilters: true })
    },
  })

  const onChangeFileExpansion = (updatedExpandedFile) => {
    saveParamValueInQueryString({ expandedFile: updatedExpandedFile }, true)
    if (updatedExpandedFile) {
      dispatch.AssetPage.fetchOpenSourceFilesDetails({
        assetId,
        openSourceFiles: updatedExpandedFile,
        ...currentQueryStringParams,
      })
      assetPageTracker.openSourceFileClicked()
    }
  }

  useEffect(() => {
    if (expandedFile && loaded) {
      dispatch.AssetPage.fetchOpenSourceFilesDetails({
        assetId,
        openSourceFiles: [expandedFile],
        ...currentQueryStringParams,
      })
      setTimeout(() => {
        scrollToElementWithId(toElementId(expandedFile))
      }, 0)
    }
  }, [loaded])

  return (
    <SubPage isLoading={!loaded} name="OpenSource" sx={{ height: '100%' }}>
      <OpenSourceView
        asset={asset}
        setQueryStringParam={setQueryStringParam}
        clearQueryStringParam={clearQueryStringParam}
        currentQueryStringParams={currentQueryStringParams}
        trackFilter={trackFilter}
        trackSort={trackSort}
        expandedFile={expandedFile}
        loaded={loaded}
        onPaginationChange={onPaginationChange}
        isRefreshing={isRefreshing}
        isRefreshingOpenSourceFileDetails={isRefreshingOpenSourceFileDetails}
        openSourceFiles={openSourceFiles}
        onChangeFileExpansion={onChangeFileExpansion}
        pagination={pagination}
        actionHandlers={handlers}
        onIssueSelectionChanged={onIssueSelectionChanged}
        selectedIssues={selectedIssues}
        isDownloadingExport={isDownloadingExport}
        exportActions={exportActions}
        integrations={integrations}
        onActionIntegrationSubmit={refetchAllData}
        filtersData={filtersData}
        assignableMembers={assignableMembers}
        isDataFiltered={isDataFiltered}
        isLoadingAssignableMembers={isLoadingAssignableMembers}
        isAssignableMembersLoaded={isAssignableMembersLoaded}
      />
    </SubPage>
  )
}

export default OpenSourceList
