/** @jsx jsx */
import {
  AssetPageTab,
  IssueKind,
  IssueStatus,
  SortDirection,
} from '@spectral/types'
import { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { jsx } from 'theme-ui'
import { omit } from 'lodash'
import AssetPage from '../../blocks/asset-page'
import { Page } from '../../components/page'
import useQueryStringState from '../../hooks/useQueryStringState'
import { Dispatch, select } from '../../redux/store'
import { paths } from '../../routes'
import { secretsListInitialParams } from '../Secrets/secrets-view'
import { iacListInitialParams } from '../iac-list'
import { openSourceListInitialParams } from '../open-source-list'
import { secretsSprawlListInitialParams } from '../Secrets/secrets-sprawl'
import { discoverIssuesListInitialParams } from '../Discover/discover-view'
import { scanHistoryInitialParams } from '../scan-history'

const tabsInitialParams = {
  [AssetPageTab.Secrets]: { ...secretsListInitialParams },
  [AssetPageTab.Iac]: { ...iacListInitialParams },
  [AssetPageTab.OpenSource]: { ...openSourceListInitialParams },
  [AssetPageTab.SecretsSprawl]: { ...secretsSprawlListInitialParams },
  [AssetPageTab.DiscoverIssues]: { ...discoverIssuesListInitialParams },
  [AssetPageTab.ScansHistory]: { ...scanHistoryInitialParams },
}

export default () => {
  const [
    queryStringState,
    setQueryStringParam,
    clearParam,
  ] = useQueryStringState([
    'tab',
    'assignees',
    'issueId',
    'referringSource',
    'status',
  ])
  const dispatch: Dispatch = useDispatch()
  const history = useHistory()
  const { assetId } = useParams()
  const { assetInfo, status } = useSelector(select.AssetPage.assetPage)
  const { loaded, pageError } = status
  useEffect(() => {
    const setSingleIssueQuery = (issue) => {
      if (issue && issue.kind === IssueKind.Iac) {
        const query = {
          tab: AssetPageTab.Iac,
          expandedFile: issue.filePath,
          path: issue.filePath,
        }
        setQueryStringParam(query, true)
      } else if (issue && issue.kind === IssueKind.Cicd) {
        const query = {
          tab: AssetPageTab.DiscoverIssues,
        }
        setQueryStringParam(query, true)
      }
    }
    if (queryStringState.issueId) {
      dispatch.AssetPage.fetchSingleIssue({
        filters: {
          issueId: queryStringState.issueId,
          assetId,
          sortBy: 'severity',
          sortDirection: SortDirection.DESC,
        },

        callBack: setSingleIssueQuery,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [_, saveParamValueInQueryString] = useQueryStringState([
    'tab',
    'sortBy',
    'sortDirection',
  ])

  const resetTabParams = (newTab: AssetPageTab) =>
    saveParamValueInQueryString(omit(tabsInitialParams[newTab], 'page'))

  const onTabChanged = (newTab: AssetPageTab) => {
    if (newTab !== queryStringState.tab) {
      resetTabParams(newTab)
    }
  }

  useEffect(() => {
    const issueTabs = [
      AssetPageTab.Secrets,
      AssetPageTab.Iac,
      AssetPageTab.OpenSource,
      AssetPageTab.DiscoverIssues,
    ]
    if (issueTabs.includes(queryStringState.tab)) {
      const currentStatus = queryStringState.status
      dispatch.AssetPage.resetFilters()
      if (!currentStatus) {
        setQueryStringParam({ status: IssueStatus.Active }, true)
      }
    }
  }, [queryStringState.tab])

  const decodedAssetId = decodeURIComponent(assetId)

  const fetchAsset = () => {
    dispatch.AssetPage.fetchAssetInfo({
      assetId,
    })
  }

  useEffect(() => {
    fetchAsset()
    return dispatch.AssetPage.resetAssetInfo
  }, [])

  const clearCurrentAsset = useCallback(() => {
    history.push(paths.closed.assets)
  }, [history])

  useEffect(() => {
    // monitor the source of the urls without counting them twice in case of reloading the page
    if (queryStringState.referringSource) {
      clearParam('referringSource')
    }
  }, [])

  return (
    <Page
      sx={{
        padding: '20px 0 0 40px',
        height: '100%',
      }}
      name="ASSET"
      title={`Asset: ${decodedAssetId}`}
      referringSource={queryStringState.referringSource}
      isError={pageError}
      isLoading={!loaded}
    >
      <AssetPage
        assetInfo={assetInfo}
        isAssetInfoLoaded={loaded}
        fetchAsset={fetchAsset}
        clearCurrentAsset={clearCurrentAsset}
        onTabChanged={onTabChanged}
        tab={queryStringState.tab}
      />
    </Page>
  )
}
