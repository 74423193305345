import { paths } from '../routes'
import { scans, cubes, sources } from './icons'

export default {
  [paths.closed.hive]: {
    text: 'Add Sources',
    to: paths.closed.sources,
    icon: sources,
  },
  [paths.closed.sources]: {
    text: 'View Dashboard',
    to: paths.closed.hive,
    icon: cubes,
  },
  [paths.closed.reports]: {
    text: 'View Scans',
    to: paths.closed.hive,
    icon: scans,
  },
  [paths.closed.team]: {
    text: 'View Dashboard',
    to: paths.closed.hive,
    icon: cubes,
  },
}
