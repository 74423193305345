import * as Sentry from '@sentry/react'
import L from 'lodash'

// action name + list of keys to hide from Sentry
const sensitivePayloadKeys = {
  'Teams/setCurrentTeam': ['key', 'members'],
  'Teams/setPagerDutySettings': ['routingServiceKey'],
  'Teams/setMsTeamsUrl': ['msTeamsWebhookUrl'],
  'Teams/setSlackUrl': ['slackWebhookUrl'],
  'Teams/setMondaySettings': ['token'],
  'Teams/setJiraSettings': ['integratorEmail', 'integratorApiToken'],
  'Teams/setEventsWebhookSettings': ['secret', 'webhookURL'],
  'Teams/setCustomWebhookSettings': ['webhookUrl', 'signatureToken'],
  'Auth/setUser': ['token'],
  'Auth/me': ['token'],
  'Assets/set': ['payload'],
  'Recover/recover': ['email'],
  'RecoverFinish/recoverValidate': ['resetToken'],
  'RecoverFinish/recoverFinish': ['resetToken', 'password', 'passwordAgain'],
}

// actions to redact full payload
const sensitivePayloadActions = ['Auth/login', 'Teams/setSettings']

const secretStateBlocks = [
  'Auth.user.token',
  'Auth.user.username',
  'Auth.user.firstName',
  'Auth.user.avatarUrl',
  'Auth.user.lastName',
  'Teams.current.data.key',
  'Teams.settings.data',
  'Assets.assets.data',
]

const clearActionSensitiveData = (action) => {
  const actionName = action.type
  const reductionText = '[Filtered By Spectral]'

  if (sensitivePayloadActions.includes(actionName)) {
    return { ...action, payload: reductionText }
  }

  const sensitiveFields = sensitivePayloadKeys[actionName]
  if (sensitiveFields) {
    const newPayload = Array.isArray(action.payload)
      ? reductionText
      : L.reduce(
          action.payload,
          (res, value, key) => {
            if (sensitiveFields.includes(key)) {
              res[key] = reductionText
            } else {
              res[key] = value
            }
            return res
          },
          {}
        )
    return { ...action, payload: newPayload }
  }

  return action
}

const clearStateSensitiveData = (state) => {
  const transformedState = L.cloneDeep(state)
  // eslint-disable-next-line no-restricted-syntax
  for (const sensitive of secretStateBlocks) {
    L.set(transformedState, sensitive, '[Filtered By Spectral]')
  }
  return transformedState
}

export const SentryEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    return clearActionSensitiveData(action)
  },
  stateTransformer: (state) => {
    return clearStateSensitiveData(state)
  },
})
