import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { IssueIgnoreAction, ReportIssue } from '@spectral/types'
import { Button, notification, Popover, Tooltip } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { Box } from 'theme-ui'
import IgnoreMenu from '../ignore-panel'
import IgnoreApprovalPrompt from './ignore-approval-prompt'

const MAX_ALLOWED_ISSUES_TO_SELECT = 100

interface Props {
  onIgnoreClicked: (any) => void
  onSnoozeClicked: (any) => void
  selectedIssues: Array<ReportIssue>
  compact?: boolean
  buttonType: string
}
const IgnoreButton = ({
  onIgnoreClicked,
  onSnoozeClicked,
  selectedIssues,
  compact = false,
  buttonType,
}: Props) => {
  useEffect(() => {
    setIgnoreApproved(false)
  }, [selectedIssues.length])

  const [ignoreApproved, setIgnoreApproved] = useState<boolean>(false)
  const [isApprovalPromptLoading, setIsApprovalPromptLoading] = useState<
    boolean
  >(false)
  const [ignorePopoverVisible, setIgnorePopoverVisible] = useState<boolean>(
    false
  )
  const [removeIgnorePopoverVisible, setRemoveIgnorePopoverVisible] = useState<
    boolean
  >(false)

  const onIgnoreApproveClicked = () => {
    setIgnoreApproved(true)
  }

  const onUpdateFinished = () => {
    setIgnorePopoverVisible(false)
    setRemoveIgnorePopoverVisible(false)
    setIsApprovalPromptLoading(false)
  }

  const handleIgnoreApprovalVisibleChange = (newVisible: boolean) => {
    handlePopoverVisibilityChange(newVisible, setIgnorePopoverVisible)
  }

  const handleRemoveIgnoreApprovalVisibleChange = (newVisible: boolean) => {
    handlePopoverVisibilityChange(newVisible, setRemoveIgnorePopoverVisible)
  }

  const handlePopoverVisibilityChange = (
    newVisible: boolean,
    setVisibility: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setVisibility(newVisible)
  }

  const tooltipContent =
    selectedIssues.length > MAX_ALLOWED_ISSUES_TO_SELECT
      ? `You can select a maximum of ${MAX_ALLOWED_ISSUES_TO_SELECT} issues`
      : null

  const renderIgnorePopoverContent = useMemo(() => {
    return ignoreApproved ? (
      <IgnoreMenu
        onIgnoreClicked={onIgnoreClicked}
        onSnoozeClicked={onSnoozeClicked}
        selectedIssues={selectedIssues}
        buttonType={buttonType}
        onUpdateFinished={onUpdateFinished}
      />
    ) : (
      <IgnoreApprovalPrompt
        onApproveClick={onIgnoreApproveClicked}
        isLoading={isApprovalPromptLoading}
      />
    )
  }, [ignoreApproved, selectedIssues, buttonType])

  const ignored = selectedIssues.filter((issue) => issue.ignore.isIgnored)
  const notIgnored = selectedIssues.filter((issue) => !issue.ignore.isIgnored)
  const view: string =
    // eslint-disable-next-line no-nested-ternary
    (ignored.length === 0 && notIgnored.length === 0) ||
    (ignored.length !== 0 && notIgnored.length !== 0) ||
    selectedIssues.length > MAX_ALLOWED_ISSUES_TO_SELECT
      ? 'disabled'
      : ignored.length > notIgnored.length
      ? `exclude`
      : `ignore`
  switch (view) {
    case 'disabled':
      return (
        <Tooltip title={tooltipContent || (compact && 'Ignore')}>
          <Button
            shape={compact ? 'circle' : null}
            icon={<EyeInvisibleOutlined />}
            disabled
          >
            {!compact && 'Ignore'}
          </Button>
        </Tooltip>
      )
    case 'ignore':
      return (
        <Box>
          <Popover
            visible={ignorePopoverVisible}
            onVisibleChange={handleIgnoreApprovalVisibleChange}
            placement="bottomLeft"
            title={null}
            trigger="click"
            content={renderIgnorePopoverContent}
          >
            <Tooltip title={compact && 'Ignore'}>
              <Button
                shape={compact ? 'circle' : null}
                icon={<EyeInvisibleOutlined />}
              >
                {!compact && `Ignore (${selectedIssues.length})`}
              </Button>
            </Tooltip>
          </Popover>
        </Box>
      )
    default:
      return (
        <Popover
          visible={removeIgnorePopoverVisible}
          onVisibleChange={handleRemoveIgnoreApprovalVisibleChange}
          placement="bottomLeft"
          title={null}
          trigger="click"
          content={
            <IgnoreApprovalPrompt
              isLoading={isApprovalPromptLoading}
              onApproveClick={() => {
                setIsApprovalPromptLoading(true)
                onIgnoreClicked({
                  issues: selectedIssues,
                  ignoreAction: IssueIgnoreAction.remove,
                  buttonType,
                  onUpdateFinished,
                })
              }}
            />
          }
        >
          <Tooltip title={compact && 'Remove ignore'}>
            <Button
              icon={<EyeOutlined />}
              shape={compact ? 'circle' : null}
              disabled={view === 'disabled'}
            >
              {!compact && `Remove ignore (${selectedIssues.length})`}
            </Button>
          </Tooltip>
        </Popover>
      )
  }
}

export default IgnoreButton
