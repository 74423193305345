import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { Box } from 'theme-ui'

const IgnoreApprovalPrompt = ({ onApproveClick, isLoading }) => {
  const onApprove = () => {
    onApproveClick()
  }

  return (
    <Box sx={{ minWidth: '300px', maxWidth: '300px', minHeight: '100px' }}>
      <Box>
        This action will be applied to all issues within this asset that share
        the same combination of fingerprint, path, and detector as the selected
        issues.
        <Box sx={{ marginTop: '15px' }}>
          <Button loading={isLoading} onClick={onApprove}>
            Approve
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default IgnoreApprovalPrompt
